@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300;400;500;700&family=Montserrat:wght@400;500;700&family=Poppins:wght@400;500;600;700&display=swap');

html,body,#root,main{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #F5F5F5;
}


@font-face {
  font-family: 'Din';
  font-weight: 700;
  src: local('Din'), url(./fonts/din_bold.otf) format('opentype');
}
@font-face {
  font-family: 'Rady';
  font-weight: 700;
  src: local('Rady'), url(./fonts/rady_bold.otf) format('opentype');
}
@font-face {
  font-family: 'Rady';
  font-weight: 500;
  src: local('Rady'), url(./fonts/rady_medium.otf) format('opentype');
}
@font-face {
  font-family: 'Rady';
  font-weight: 400;
  src: local('Rady'), url(./fonts/rady_regular.otf) format('opentype');
}

@font-face {
  font-family: 'Din';
  font-weight: 500;
  src: local('Din'), url(./fonts/din_medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Din';
  font-weight: 400;
  src: local('Din'), url(./fonts/din_regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: local('Inter'), url(./fonts/Inter_bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('DiIntern'), url(./fonts/Inter_medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'), url(./fonts/Inter_regular.ttf) format('truetype');
}
@font-face {
  font-family: 'SF-display';
  font-weight: 400;
  src: local('SF-display'), url(./fonts/SF-Pro-Display-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'SF-display';
  font-weight: 500;
  src: local('SF-display'), url(./fonts/SF-Pro-Display-Medium.otf) format('truetype');
}
@font-face {
  font-family: 'SF-display';
  font-weight: 600;
  src: local('SF-display'), url(./fonts/SF-Pro-Display-Semibold.otf) format('truetype');
}

@font-face {
  font-family: 'SF-display';
  font-weight: 700;
  src: local('SF-display'), url(./fonts/SF-Pro-Display-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'SF-display';
  font-weight: 800;
  src: local('SF-display'), url(./fonts/SF-Pro-Display-Black.otf) format('truetype');
}


th > label {
  margin-bottom: 0 !important;
}
/* Pagination */
.page-link>span.visually-hidden {
  display: none;
}
.page-item.active .page-link {
  background-color: #2356c0 !important;
  border-color: #2355c06c !important;
}
.pagination{
  background-color: #F5F5F5;
  color: #27272A !important;
  font-size: 14px !important;
  width: max-content;
}
a.page-link, span.page-link{
  border: 1px solid #E4E4E7 !important;
}

.page-item:first-child > .page-link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid #E4E4E7;
  overflow: hidden;
}
.page-item:last-child > .page-link{
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #E4E4E7;
  overflow: hidden;
}
/* end pagination */

.MuiIconButton-root:focus{
  outline: none !important;
}
a.active.navbar-brand:hover {
  color: #f8eeea !important;
}
h3 {
  padding: 1rem 0;
}
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.selected-flag {
  padding: 0 !important;
  /* left:10px !important; */
}

/* MUI Dropdown */
.MuiInputBase-root{
  width: 100%;
  height: max-content;
  color:#565656;
  text-align: left;
  font-family: "Din" !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  
}
#small-dropdown{
  font-weight: 700 !important;
  color: #565656 !important;
}
div#custom-multiple-checkbox{
  white-space: pre-wrap !important;
}
li.MuiButtonBase-root.MuiMenuItem-root, div.MuiListItemText-root> span{
  font-family: "SF-display";
  font-size: 0.85rem;
  display:flex;
  flex-direction:row;
  justify-content:start;
  align-items:center;
  padding: 5px 0 5px 5px ;
}
/*  */

/* Maps */
.leaflet-container {
  width: 100%; 
  height: 100%;
}
.leaflet-touch .leaflet-geosearch-bar form input {
  height: 30px;
  padding-right: 10px;
}
.leaflet-touch .leaflet-geosearch-bar form input::placeholder {
  font-family: "SF-display";
}
form .results{
  display: flex;
  flex-direction: column;
  align-items: end;
  overflow-y: scroll;
  max-height: 180px;
  scroll-behavior: smooth;
}
form .results::-webkit-scrollbar-thumb {
  background-color: #347267;
  border-radius: 6px;
}
form .results::-webkit-scrollbar {
  width: 8px;
  background: #F6F6F6;
  border-radius: 6px;
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  border-bottom: 1px solid #F0F0F0 !important;
  white-space: pre-wrap !important; 
  text-overflow: none;
  margin-right: 8px;
  font-size: 0.7rem;
  font-family: "SF-display";
  margin-bottom: 8px;
  width: 100%;
  overflow:visible !important
}
.leaflet-control-geosearch .results > *:hover {
  border: none;
}

/*  Nav Bar */
.nav-item{
  margin-right: 0 !important;
}
.nav-item > .active{
  background-color: #3472670e !important;

}

/* Accordion */
.accordion-button::after{
  margin-right: 0 !important;
  margin-right: auto !important;
}
.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.accordion-item{
  margin-bottom: 20px !important;
}
.accordion-button{
  padding: 10px 20px !important;
  background-color: #2356c0!important;
  color: white !important
}
#accordion-org > .accordion-button{
  padding: 2px 20px !important;
  background-color: #f98c64 !important;
  color: white !important;
  box-shadow: none !important
}
#accordion-org {
  background-color: #f98c64 !important;
}
.accordion-header{
  background-color: #2356c0!important;
  border-radius: 0.375rem !important;
}
.accordion-button:focus {
  box-shadow: none !important;
  border-color: rgba(0,0,0,.125) !important;
}
.nav-link, .page-item> .page-link{
  color: #212529 !important;
}
.page-item.active > .page-link{
  color: white !important;
background-color: #ffa927;
}

/*  Search Animation */
.search-enter{
  opacity: 0.01;
}

.search-enter.search-enter-active{
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.search-leave{
  opacity: 1;
}

.search-leave.search-leave-active{
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.search-date{
  width: 100% !important;
}

 .fb_iframe_widget_fluid_desktop iframe{
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
/*
.fb-video, .fb-video span{
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
} */

.table thead th{
  text-transform: capitalize !important;
}


.MuiMenuItem-root svg {
  margin-right: 8px !important;
  margin-left: 0 !important;

}